<template>
    <div class='teacher__details'>
        <Preloader v-if='loader' />

        <h2 class='mb-3'>Начисления</h2>

        <div v-if='isAdmin' class='row mb-4'>
            <div class='col-6'>
                <b-button type='button' class='custom-button-md mr-3' variant='success' @click='callModalPay'>
                    Создать выплату
                </b-button>
                <b-button
                    type='button'
                    class='custom-button-md mr-3'
                    variant='outline-primary'
                    @click='callModalSurcharge'
                >
                    Доплата / Вычет
                </b-button>
            </div>

            <div class='col-6'>
                <b-form v-if="teacher" class='display-flex justify-end form-category'>
                    <b-form-select
                        :options="[{value: null, text: 'Выберите категорию', disabled: true}, ...categoryList]"
                        :disabled='!isAdmin'
                        v-model='salaryCategory'
                    />

                    <button
                        type='submit'
                        @click.prevent='saveSalaryCategory'
                        :disabled='teacher.salary_category_id == salaryCategory'
                        class='btn btn-success custom-button-md ml-2'
                    >
                        Сохранить
                    </button>
                </b-form>
            </div>
        </div>

        <TeacherSalariesSection
            v-if='teacher'
            :view-type='viewType'
            :salaries='salaries'
            :students="students"
            :penalties="penalties"
            :payments="payments"
            :teacher='teacher'
            :total='total'
            @on-view-type-change='handleViewTypeChange'
            @on-date-select='handleDateSelect'
            @on-penalties-delete='handlePenaltiesDelete'
            @on-payments-delete='handlePaymentsDelete'
        />

        <div v-if='pagination && pagination.current_page <= pagination.last_page' class='overflow-auto'>
            <b-pagination
                class='paginator'
                :total-rows='pagination.total'
                :per-page='pagination.per_page'
                @page-click='handlePageSelect'
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import TeacherSalariesSection from '@/components/admin/teacher-salary/TeacherSalariesSection.vue';
import TeacherSalaryModalSurcharge from '@/components/admin/teacher-salary/TeacherSalaryModalSurcharge.vue';
import TeacherSalaryModalPay from '@/components/admin/teacher-salary/TeacherSalaryModalPay.vue';

export default {
    name: 'TeacherSalary',
    components: {
        TeacherSalariesSection,
        Preloader: () => import('@/components/Preloader'),
    },
    data() {
        return {
            loader: false,
            pagination: null,
            teacherId: this.$route.params.id,
            teacher: null,
            categoryList: [],
            salaryCategory: null,
            salaries: null,
            students: [],
            penalties: [],
            payments: [],
            total: null,
            viewType: 'TeacherSalaryTable',
        };
    },
    async created() {
        this.loader = true;
        await Promise.all([
            this.setQueryDate(),
            this.fetchTeacher(),
            this.fetchCategoryList(),
            this.fetchData(this.$route.query),
            this.fetchTotal(this.$route.query)
        ]);
        this.loader = false;
    },
    computed: {
        ...mapGetters({
            getRole: 'user/getRole',
            getTeacherUserDetail: 'teacher/getTeacherUserDetail',
        }),
        isAdmin() {
            return this.getRole === 'admin';
        },
    },
    methods: {
        async setQueryDate() {
            const query = Object.assign(
                {},
                this.$route.query,
                {
                    date_from: dayjs().set('date', 1).format('YYYY-MM-DD'),
                    date_to: dayjs().endOf('month').format('YYYY-MM-DD'),
                },
            );
            await this.$router.push({ query });
        },
        async fetchCategoryList() {
            try {
                const { data } = await this.$axios.get('crm/salary-categories');
                this.categoryList = data.data.map(c => {
                    return { value: c.id, text: c.title };
                });
            } catch (ex) {
                console.log('cant fetch data: ', ex);
            }
        },
        async fetchTeacher() {
            try {
                const { data } = await this.$axios.get(`/api/teacher/${this.teacherId}`);
                this.teacher = data;
                this.salaryCategory = this.teacher.salary_category_id;
            } catch (ex) {
                console.log('cant fetch teacher: ', ex);
            }
        },
        async saveSalaryCategory() {
            if (!this.isAdmin) return false;

            try {
                await this.$axios.post(`crm/teachers/${this.teacherId}/update-salary-category`, {
                    salary_category_id: this.salaryCategory,
                });
                await this.fetchTeacher();
                this.$toasted.success('Категория обновлена', { position: 'bottom-right' });
            } catch (e) {
                this.$toasted.error(e, { position: 'bottom-right' });
            }
        },
        async handlePageSelect(page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, { page });
                await this.$router.push({ query });
                await this.fetchData(this.$route.query);
            }

            return false;
        },
        async handleDateSelect(query) {
            if (query) {
                await this.$router.push({ query });
                await this.fetchData(this.$route.query);
                await this.fetchTotal(this.$route.query);
            }

            return false;
        },
        async handleViewTypeChange(type) {
            this.viewType = type;
            this.loader = true;
            await this.fetchData(this.$route.query);
            this.loader = false;
        },
        async fetchData(query = null) {
            if (this.viewType === 'TeacherSalaryTable') {
                try {
                    const { data } = await this.$axios.get(`crm/teachers/${this.teacherId}/teacher-salary`, { params: query });
                    this.students = data.data;
                    this.pagination = null;
                } catch (ex) {
                    console.log('cant fetch teacher salary list: ' + ex);
                }

                try {
                    const { data } = await this.$axios.get(`crm/teachers/${this.teacherId}/teacher-salary-list`, { params: {
                        ...query,
                        types: [3, 4],
                        limit: 1000,
                    }});
                    this.penalties = data.data;
                    this.pagination = null;
                } catch (ex) {
                    console.log('cant fetch teacher salary list: ' + ex);
                }

                try {
                    const { data } = await this.$axios.get(`crm/teachers/${this.teacherId}/teacher-payouts`, { params: {
                            ...query,
                        } });
                    this.payments = data.data;
                    this.pagination = null;
                } catch (ex) {
                    console.log('cant fetch teacher salary list: ' + ex);
                }
            } else if (this.viewType === 'TeacherSalaryList') {
                try {
                    const { data } = await this.$axios.get(`crm/teachers/${this.teacherId}/teacher-salary-list`, { params: query });
                    this.salaries = data.data;
                    this.pagination = data.meta;
                } catch (ex) {
                    console.log('cant fetch teacher salary list: ' + ex);
                }
            }
        },
        async fetchTotal(query = null) {
            try {
              const {data} = await this.$axios.get(`crm/teachers/teacher-salary/total`, {params: {...query, teacher_id: this.teacherId }});
              this.total = data;
            } catch (ex) {
              console.log('cant fetch teacher salary total: ' + ex);
            }
        },
        async fetchDataAfterModalClose(event) {
            if (event.params && event.params.refetch) {
                this.loader = true;
                await this.fetchData(this.$route.query);
                await this.fetchTotal(this.$route.query);
                this.loader = false;
            }

            return false;
        },
        async handlePenaltiesDelete() {
            this.loader = true;
            await this.fetchData(this.$route.query);
            await this.fetchTotal(this.$route.query);
            this.loader = false;
        },
        async handlePaymentsDelete() {
            this.loader = true;
            await this.fetchData(this.$route.query);
            await this.fetchTotal(this.$route.query);
            this.loader = false;
        },
        callModalSurcharge() {
            this.$modal.show(TeacherSalaryModalSurcharge, {
                form: {
                    teacher_id: this.teacher.id,
                    teacher_name: this.teacher.short_name,
                    payment_type: 3,
                    date: dayjs().format('YYYY-MM-DD'),
                    price: null,
                    comment: null,
                },
            }, {
                name: 'TeacherSalaryModalContainerSurcharge',
                width: '400px',
                height: 'auto',
                scrollable: false,
            }, {
                'before-close': (event) => this.fetchDataAfterModalClose(event),
            });
        },
        callModalPay() {
            this.$modal.show(TeacherSalaryModalPay, {
                form: {
                    teacher_id: this.teacher.id,
                    teacher_name: this.teacher.short_name,
                    date: {
                        start: this.$route.query.date_from ? dayjs(this.$route.query.date_from).format('YYYY-MM-DD') : dayjs().subtract(1, 'month').set('date', 1).format('YYYY-MM-DD'),
                        end: this.$route.query.date_to ? dayjs(this.$route.query.date_to).format('YYYY-MM-DD') : dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
                    },
                },
            }, {
                name: 'TeacherSalaryModalContainerPay',
                width: '992px',
                height: 'auto',
                scrollable: true,
            }, {
                'before-close': (event) => this.fetchDataAfterModalClose(event),
            });
        },
    },
};
</script>

<style lang='scss' scoped>
::v-deep {
    .form__group {
        justify-content: flex-end;

        label {
            margin: 0;
        }
    }
}

.clear-input {
    position: absolute;
    right: 10px;
    color: lighten(red, 20);
    top: 50%;
    transform: translateY(-50%);
}

.form-category {
    justify-content: end;

    select {
        width: 300px;
        height: 44px;
    }
}
</style>