<template>
  <div>
    <Preloader v-if="loading"/>
    <b-form class='row'>
      <b-form-group label="Преподаватель:" class="col-12" label-for="input-teacher">
        <multiselect
          class='custom__multiselect'
          v-model='selectedTeacherIds'
          :options='teachersOptionsList'
          :multiple='true'
          :close-on-select='false'
          :clear-on-select='false'
          :preserve-search='true'
          selectedLabel='выбран'
          selectLabel='нажмите чтобы выбрать'
          deselectLabel='нажмите чтобы убрать'
          placeholder='введите имя'
          :tagable='true'
          tagPosition='bottom'
          label='text'
          track-by='value'
          @input='chooseTeacherHandler'
        />
      </b-form-group>

      <b-form-group label="От:" class="col-6" label-for="input-date_from">
        <b-form-datepicker
          id="input-date_from"
          v-model="date_from"
          :value="date_from"
          :data-date-format="'mm.dd.yyyy'"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ru"
          @input='getTeachers'
          required
        />
      </b-form-group>

      <b-form-group label="По:" class="col-6 mb-5" label-for="input-date_to">
        <b-form-datepicker
          id="input-date_to"
          v-model="date_to"
          :value="date_to"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ru"
          @input='getTeachers'
          required
        />
      </b-form-group>

    </b-form>

    <div v-if="stats && stats.length > 0 && orderByList && orderByList.length > 0">
      <table class='table table-hover'>
        <thead class='table_head'>
          <tr class="text-center font-weight-bold">
            <th class="column-sort" v-for="(item, idx) in orderByList" :key="idx" @click="sort(item.field)">
              <div class="column-sort-content">
                <span>{{item.title}}</span>
                <svg
                  width="15px"
                  height="15px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  version="1.1"
                  id="Layer_1"
                  class="column-sort-arrow"
                  style="enable-background:new 0 0 512 512;"
                  :class="{ 'column-sort-arrow-active': orderBy === item.field, 'column-sort-arrow-active-rotate': orderBy === item.field && sortDirection === 'DESC'}"
                >
                  <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"/>
                </svg>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in stats"
            :key="key"
            class="text-center"
          >
            <td class="text-left">{{ item.teacher_name }}</td>
            <td>{{ item.all_students }}</td>
            <td>{{ item.all_lessons }}</td>
            <td>{{ item.reviews }}</td>
            <td>{{ item.status_planned_lessons }}</td>
            <td>{{ item.status_finished_lessons }}</td>
            <td>{{ item.status_skipped_lessons }}</td>
            <td>{{ item.type_trial_lessons }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      <p>Результатов не найдено</p>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import moment from "moment";
import Preloader from "../../../components/Preloader";

export default {
  name: "StatsListTeachers",
  components: {
    Multiselect,
    Preloader,
  },
  data() {
    return {
      teacher_name: null,
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
      stats: null,
      orderByList: [],
      orderBy: null,
      sortDirection: 'ASC',
      loading: false,
      currentPage: this.$route.query.page || 1,
      totalPage: 0,
      perPage: 0,
      routerParams: {...this.$route.query},
      teachersOptionsList: [],
      selectedTeacherIds: [], // список выбранных преподавателей
      prevSelectedTeacherIds: [], // прошлый выбор
    }
  },
  computed: {
    ...mapGetters({
      getTeachersOptionsList: 'teacher/getTeachersOptionsList',
    }),
  },
  methods: {
    ...mapActions({
      fetchTeachersOptions: 'teacher/fetchTeachersOptions',
    }),
    async fetchSortColumnTable() {
      try {
        const { data } = await this.$axios.get(`api/teachers/stats-sort-column`);

        this.orderByList = data.data;
        this.orderBy = this.orderByList[0].field;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(`Error get sort columt for teachers stats: ` . e)
      }
    },
    async loadTeacherOptions() {
      return this.fetchTeachersOptions({});
    },
    async getTeachers() {
      this.loading = true;

      const from = moment(this.date_from).format('DD.MM.YYYY');
      const to = moment(this.date_to).format('DD.MM.YYYY');

      let teacher_ids = [];

      this.selectedTeacherIds.forEach(item => {
        teacher_ids.push(item.value);
      });

      if (teacher_ids.find(item => item === -1)) {
        teacher_ids = [];
      }

      let params = {
        teacher_ids,
        from,
        to,
        order_by : this.orderBy,
        sort_direction : this.sortDirection,
      }

      try {
        const { data } = await this.$axios.post(`/api/teachers/stats/`, params);

        this.stats = data.data;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(`Error get statistics teacher: ` . e)
      }
    },
    sort(field) {
      this.orderBy = field;
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      this.getTeachers();
    },
    chooseTeacherHandler(teachers, old) {
      if (teachers.length === 0) {
        if (this.teachersOptionsList.length > 0) {
          const firstTeacher = this.teachersOptionsList.find(item => (item.value !== -1));

          this.selectedTeacherIds = [firstTeacher];
        }

      } else {
        for (const teacher of teachers) {
          if (teacher.value == -1) {
            this.selectedTeacherIds = [teacher];
            break;
          }
        }

      }

      this.prevSelectedTeacherIds = this.selectedTeacherIds;
      this.getTeachers();
    },
  },
  watch: {
    getTeachersOptionsList: function(options) {
      if (options.length > 0) {
        const allTeachersSelect = { value: -1, text: 'Все преподаватели' };
        this.teachersOptionsList = [allTeachersSelect, ...options];

        // По умолчанию выбираем всех учителей
        this.selectedTeacherIds = [allTeachersSelect]

      } else {
        this.selectedTeacherIds = [];
        this.teachersOptionsList = [];
      }

      this.getTeachers();
    },
  },
  async created() {
    await this.fetchSortColumnTable()
    await this.loadTeacherOptions()
  }
}
</script>

<style lang="scss" scoped>
.column-sort {
  cursor: pointer;

  &-content {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &-arrow-active {
    fill: #FD5F00;
    color: #FD5F00;

    &-rotate {
      transform: rotate(180deg);
    }
  }
}
</style>
