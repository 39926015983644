<template>
    <div class='teacher-salaries-section'>
        <div class='d-flex items-center w-100 mb-4'>
            <VCalendar
                class='teacher-salaries-section-calendar'
                mode='date'
                is-range
                :popover="{ visibility: 'focus' }"
                :masks="{ input: ['DD.MM.YYYY']}"
                @input='handleDateSelect'
                v-model='date'
            >
                <template v-slot='{ togglePopover }'>
                    <div @click='togglePopover()'>
                        <input
                            class='form-control'
                            :class="{'hidden': !date.start}"
                            :value='`с ${formatDate(date.start)} по ${formatDate(date.end)}`'
                            readonly
                        />
                    </div>
                </template>
            </VCalendar>

            <div class='d-flex justify-content-end ml-auto'>
                <TeacherSalariesTotal :total='this.total' />

                <b-button
                    v-for='(type) in viewTypes'
                    :key='type.value'
                    type='button'
                    class='ml-3'
                    :variant="viewType === type.value ? 'primary' : 'outline-primary'"
                    :title='type.text'
                    @click='changeViewType(type.value)'
                >
                    {{ type.text }}
                </b-button>
            </div>
        </div>


        <div class='widget'>
            <TeacherSalaryList v-if="viewType === 'TeacherSalaryList'" :salaries='salaries' @on-delete="$emit('on-penalties-delete')" />
            <TeacherSalaryTable
                v-else-if="viewType === 'TeacherSalaryTable'"
                :students='students'
                :penalties='penalties'
                :payments='payments'
                @on-penalties-delete="$emit('on-penalties-delete')"
                @on-payments-delete="$emit('on-payments-delete')"
            />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import TeacherSalaryList from '@/components/admin/teacher-salary/TeacherSalaryList';
import TeacherSalaryTable from '@/components/admin/teacher-salary/TeacherSalaryTable';
import TeacherSalariesTotal from '@/components/admin/teacher-salary/TeacherSalariesTotal';

export default {
    name: 'TeacherSalariesSection',
    components: { VCalendar, TeacherSalaryList, TeacherSalaryTable, TeacherSalariesTotal },
    props: {
        viewType: {
            type: String,
            default: 'TeacherSalaryTable',
        },
        viewTypes: {
            type: Array,
            default: () => {
                return [
                    { text: 'Таблица', value: 'TeacherSalaryTable' },
                    { text: 'Список', value: 'TeacherSalaryList' },
                ];
            },
        },
        salaries: {
            type: Array,
            default: () => [],
        },
        students: {
            type: Array,
            default: () => [],
        },
        penalties: {
            type: Array,
            default: () => [],
        },
        payments: {
            type: Array,
            default: () => [],
        },
        teacher: {
            type: Object,
            required: true,
        },
        total: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            date: {
                start: dayjs().set('date', 1).format('YYYY-MM-DD'),
                end: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
        };
    },
    methods: {
        handleDateSelect() {
            const query = Object.assign(
                {},
                this.$route.query,
                {
                    date_from: dayjs(this.date.start).format('DD.MM.YYYY'),
                    date_to: dayjs(this.date.end).format('DD.MM.YYYY'),
                    page: 1,
                },
            );
            this.$emit('on-date-select', query);
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        changeViewType(type) {
            this.$emit('on-view-type-change', type);
        },
    },
};
</script>

<style lang='scss' scoped>
.teacher-salaries-section-calendar {
    min-width: 328px;

    input {
        padding: 0.75rem 1.25rem;
        height: auto;
    }
}
</style>