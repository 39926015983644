<template>
    <div class='stats-list-tab'>
        <h4 class='mb-4 page-title font-weight-bold'>Статистика</h4>

        <b-tabs>
            <b-tab title='По школе' lazy @click='handleTabClick'>
                <StatsListSchool />
            </b-tab>

            <b-tab title='Старая статистика по учителям' lazy @click='handleTabClick'>
                <StatsListTeachers />
            </b-tab>

            <b-tab title='По учителям' lazy @click='handleTabClick'>
                <TeacherStatistic />
            </b-tab>

            <b-tab title='По зарплатам учителей' lazy @click='handleTabClick'>
                <TeacherStatisticSalary />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import StatsListSchool from '@/pages/Admin/Stats/StatsListSchool';
import StatsListTeachers from '@/pages/Admin/Stats/StatsListTeachers';

export default {
    name: 'StatsListTab',
    components: {
        TeacherStatistic: () => import('@/components/admin/teacher-statistic/TeacherStatistic.vue'),
        TeacherStatisticSalary: () => import('@/components/admin/teacher-statistic-salary/TeacherStatisticSalary.vue'),
        StatsListSchool,
        StatsListTeachers,
    },
    methods: {
        handleTabClick() {
            if (this.$route.query && Object.keys(this.$route.query).length > 0) {
                this.$router.replace({ query: {} });
            }
        }
    }
};
</script>

<style lang='scss' scoped></style>
