import { render, staticRenderFns } from "./TeacherDropdown.vue?vue&type=template&id=fca7bdb8&scoped=true"
import script from "./TeacherDropdown.vue?vue&type=script&lang=js"
export * from "./TeacherDropdown.vue?vue&type=script&lang=js"
import style0 from "./TeacherDropdown.vue?vue&type=style&index=0&id=fca7bdb8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fca7bdb8",
  null
  
)

export default component.exports