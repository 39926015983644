<template>
  <div>
    <h4>Основная информация о преподавателе</h4>
    <div class='widget'>
      <b-form class='row g-1' @submit.prevent='updateDetails'>
        <b-form-group
            id='input-group-1'
            label='ФИО:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.full_name'
              type='text'
              placeholder='ФИО'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id='input-group-1'
            label='Пол:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-select
              id='inline-form-custom-select-pref'
              class=''
              :options='getGenderList'
              :value='form.gender'
              v-model='form.gender'
          ></b-form-select>
        </b-form-group>

          <b-form-group
              id='input-group-1'
              label='Статус:'
              class='col-6'
              label-for='input-1'
          >
              <b-form-select
                  id='inline-form-custom-select-pref'
                  class=''
                  :options='statuses'
                  :value='form.status'
                  v-model='form.status'
              ></b-form-select>
          </b-form-group>

        <b-form-group
            v-if="getLanguageList"
            id="input-group-lang"
            label="Язык преподавания:"
            class="col-6"
            label-for="input-lang"
        >
          <b-form-select
              class=""
              :options="getLanguageList"
              :value="form.language"
              v-model="form.language"
              required
          ></b-form-select>
        </b-form-group>

        <b-form-group
            id='input-group-1'
            label='Телефон:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.phone'
              type='text'
              v-mask="'####################'"
              placeholder='телефон'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id='input-group-1'
            label='Почта:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.email'
              type='text'
              placeholder='email'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id='input-group-1'
            label='Zoom:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-textarea
              id='input-1'
              v-model='form.zoom_url'
              type='text'
              placeholder='ссылка зум'
          ></b-form-textarea>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='zoom'
            label='Данные по Zoom:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-group
              id='zoom-login-form'
              label='Логин'
              class='col-6'
              label-for='login-zoom'
          >
            <b-form-input
                id='login-zoom'
                v-model='form.login_zoom'
                type='text'
                placeholder='Логин пользователя zoom'
            />
          </b-form-group>
          <b-form-group
              id='zoom-login-form'
              label='Пароль'
              class='col-6'
              label-for='password-zoom'
          >
            <b-form-input
                id='password-zoom'
                v-model='form.password_zoom'
                type='text'
                placeholder='Пароль пользователя zoom'
            />
          </b-form-group>
        </b-form-group>

        <b-form-group
            id='input-group-1'
            label='Ссылка на шахматную доску:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-textarea
              id='input-1'
              v-model='form.chess_board_url'
              type='text'
              placeholder='ссылка на шахматную доску'
              disabled
          ></b-form-textarea>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>


        <b-form-group
            id='input-group-1'
            label='Данные шахматной доски:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-group label='Имя пользователя' label-for='li_nick'>
            <b-form-input
                id='li_nick'
                v-model='form.lichess_username'
                type='text'
                placeholder='Имя пользователя в шахматной доске'
            ></b-form-input>
          </b-form-group>

          <b-form-group label='Логин Тестового пользователя' label-for='li_login'>
            <b-form-input
                id='li_login'
                v-model='form.lichess_login'
                type='text'
                placeholder='логин в шахматной доске'
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback>
            логин обязателен
          </b-form-invalid-feedback>

          <b-form-group label='Пароль Тестового пользователя' label-for='li_pwd'>
            <b-form-input
                id='li_pwd'
                v-model='form.lichess_password'
                type='text'
                placeholder='пароль от шахматной доски'
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback>
            пароль обязателен
          </b-form-invalid-feedback>

          <b-form-group label='Ссылка на комнату для пробных уроков' label-for='li_room_url'>
            <b-input-group class='lichess-room-url-input-group'>
              <b-form-input
                  id='li_room_url'
                  v-model='form.lichess_room_url'
                  type='text'
                  placeholder='Ссылка на комнату'
                  ref='lichessRoomUrl'
              ></b-form-input>
              <b-input-group-append>
                <b-button variant='info' @click.prevent="(e) => copyLink(e, 'lichessRoomUrl')">Скопировать</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form-group>


        <!--                <b-form-group-->
        <!--                    label='Имя пользователя на игровой платформе'-->
        <!--                    class='col-6'-->
        <!--                    label-for='input-lichess-username'-->
        <!--                >-->
        <!--                    <b-form-input-->
        <!--                        id='input-lichess-username'-->
        <!--                        v-model='form.lichess_username'-->
        <!--                        type='text'-->
        <!--                        placeholder=''-->
        <!--                    ></b-form-input>-->
        <!--                </b-form-group>-->


        <b-form-group class='col-12' v-if="this.$route.name === 'TeacherDetail' && this.getRole !== 'teacher'">
          <hr class='flex w-100'/>
          <h4>Ссылка для входа</h4>
          <b-input-group class='access-link-input-group'>
            <b-form-input readonly :value='getAccessLink' ref='accessLink'></b-form-input>
            <b-input-group-append>
              <b-button class='right-split-btn' variant='info' @click.prevent='sendAccessLinkByEmail'>
                <b-spinner v-if='isSendingEmail' style='width: 1.4rem; height: 1.4rem;'
                           label='Sending'></b-spinner>
                <span :class='{ caption: isSendingEmail }'>Отправить на почту</span>
              </b-button>
              <b-button variant='info' @click.prevent="copyLink(e, 'accessLink')">Скопировать</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group class='col-12' v-if="this.$route.name === 'TeacherDetail' && this.getRole !== 'teacher'&&this.auth_zoom_url">
          <hr class='flex w-100'/>
          <h4>Ссылка для авторизации Zoom</h4>
          <b-input-group class='access-link-input-group'>
            <b-form-input readonly :value='this.auth_zoom_url' ref='accessLinkZoom'></b-form-input>
            <b-input-group-append>
              <b-button variant='info' @click.prevent="copyLink(e, 'accessLinkZoom')">Скопировать</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group class='col-12 text-right'>
          <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
          <b-button type='button' @click.prevent="$router.push({ name: 'TeacherList' })" variant='primary'>
            Назад
          </b-button>
        </b-form-group>

      </b-form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Multiselect from "vue-multiselect";

export default {
  name: 'TeacherProfile',
  components: {Multiselect},
  data() {
    return {
      isSendingEmail: false,
      auth_zoom_url: null,
      statuses: [],
      form: {
        id: null,
        full_name: null,
        phone: null,
        email: null,
        zoom_url: null,
        chess_board_url: null,
        gender: null,
        status: null,
        login_zoom: null,
        password_zoom: null,
        language: null,
        lichess_username: null,
        lichess_login: null,
        lichess_password: null,
        lichess_room_url: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getTeacherUserDetail: 'teacher/getTeacherUserDetail',
      getGenderList: 'common/getGenderList',
      getLanguageList: 'common/getLanguageList',
      getAccessLink: 'teacher/getAccessLink',
      getRole: 'user/getRole',
    }),
    isAddPage() {
      return this.$route.name === 'TeacherAdd';
    },
  },
  methods: {
    ...mapActions({
      fetchTeacherDetails: 'teacher/fetchTeacherDetails',
      updateTeacherDetails: 'teacher/updateTeacherDetails',
      createTeacher: 'teacher/createTeacher',
      sendAccessLink: 'teacher/sendAccessLink',
      fetchAccessLink: 'teacher/fetchAccessLink',
      fetchTeacherList: 'teacher/fetchTeacherList',
    }),
    copyLink(e, ref) {
      const elem = this.$refs[ref];
      elem.select();
      console.log(elem)
      document.execCommand('copy');
      this.$toasted.success('скопировано в буфер обмена', {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    buildFormData() {
      // TODO refactor
      for (const key in this.getTeacherUserDetail) {
        const formKeys = Object.keys(this.form);
        if (formKeys.includes(key)) {
          this.form[key] = this.getTeacherUserDetail[key];
        }
      }
      this.auth_zoom_url = this.getTeacherUserDetail.auth_zoom_url;
    },
    async sendAccessLinkByEmail() {
      this.isSendingEmail = true;
      try {
        const result = await this.sendAccessLink(this.$route.params.id);
        this.isSendingEmail = false;
        if (result.status === 'success') {
          this.$toasted.success('Письмо успешно отправлено.', {
            position: 'bottom-right',
            action: {
              text: 'Закрыть',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }
      this.isSendingEmail = false;
      this.$toasted.error(`Не удалось отправить!`, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    async updateDetails() {
      try {
        const data = {...this.form};
        if (this.isAddPage) {
          const result = await this.createTeacher(data);
          if (result.status === 'success') {
            await this.fetchTeacherDetails(this.$route.params.id);
            this.buildFormData();
            await this.$router.push({name: 'TeacherList'});
          }
        } else {
          await this.updateTeacherDetails(data);
          await this.fetchTeacherDetails(this.$route.params.id);
          this.buildFormData();
        }
        this.$toasted.success('Данные обновлены!', {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      } catch (e) {
        this.$toasted.error(`Не удалось сохранить! ${e.message}`, {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }

    },
      async fetchStatuses() {
          try {
              this.loader = true;
              const {data} = await this.$axios.get('/crm/teachers/get-statuses');
              this.statuses = data.map((v, k) => {
                  return {value: k, text: v}
              });
              this.loader = false;
          } catch (ex) {
              this.loader = false;
              console.log('cant fetch statuses: ' + ex);
          }
      },
  },
  async created() {
    await this.fetchTeacherDetails(this.$route.params.id);
    await this.fetchAccessLink(this.$route.params.id);
    await this.fetchStatuses()
    this.buildFormData();
  },

};
</script>

<style scoped lang='scss'>
.access-link-input-group {
  .right-split-btn {
    border-right: 2px solid #fff;
  }

  .caption {
    margin-left: 10px;
  }
}
</style>
