<template>
    <label class='switch'>
        <input class='switch__input' :value='value' :checked='value' type='checkbox' @input='handleInput' />
        <i class='switch__icon'></i>
        <span class='switch__span'>{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'Switcher',
    props: ['value', 'label'],
    methods: {
        handleInput() {
            this.$emit('input', !this.value)
        },
    },
};
</script>

<style lang='scss' scoped>
.switch-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    width: 100vw;
}

.switch {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &__input {
        display: none;

        &:checked + .switch__icon {
            background-color: var(--green);

            &:before {
                transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
            }

            &:after {
                transform: translate3d(20px, 2px, 0);
            }
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        max-width: 42px;
        min-width: 42px;
        min-height: 24px;
        max-height: 24px;
        background-color: #bebebe;
        border-radius: 22px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 38px;
            height: 20px;
            background-color: #bebebe;
            border-radius: 11px;
            transform: translate3d(2px, 2px, 0) scaleX(1);
            transition: all 0.25s linear;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 11px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
            transform: translate3d(2px, 2px, 0);
            transition: all 0.2s ease-in-out;
        }
    }

    &__span {
        font-size: 14px;
        margin-left: 5px;
        font-weight: 400;
    }
}

</style>