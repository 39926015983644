<template>
    <div class='teacher-salary-modal'>
        <h3 class='d-block p-4 mb-4 border-bottom border-gray'>
            <template v-if='formData.teacher_name'>{{ formData.teacher_name }} <br /></template>
            <small>Доплата / Вычет</small>
        </h3>

        <b-form class='p-4' @submit.prevent='onSubmit'>
            <b-form-group label='Доплата / Вычет:' label-for='tsm-payment-type'>
                <b-form-select
                    id='tsm-payment-type'
                    required
                    placeholder='Выберите филиал'
                    :options='payments'
                    v-model='formData.payment_type'
                />
            </b-form-group>

            <b-form-group label='Дата:' label-for='tsm-date'>
                <VCalendar mode='date' v-model='formData.date' :masks='masks'>
                    <template v-slot='{ inputValue, togglePopover }'>
                        <div class='position-relative'>
                            <input
                                id='tsm-date'
                                class='form-control'
                                :value='inputValue'
                                @click='togglePopover'
                                placeholder='Введите дату'
                                autocomplete='off'
                            >
                            <a
                                v-show='formData.date'
                                class='teacher-salary-modal-date-close'
                                @click='() => formData.date = null'
                            >
                                <i class='fa fa-close'></i>
                            </a>
                        </div>
                    </template>
                </VCalendar>
            </b-form-group>

            <b-form-group label='Размер оплаты:' label-for='tsm-price'>
                <b-form-input
                    id='tsm-price'
                    v-model='formData.price'
                    type='number'
                    placeholder='Введите размер оплаты'
                    required
                />
            </b-form-group>

            <b-form-group label='Комментарий:' label-for='tsm-comment'>
                <b-form-textarea
                    id='tsm-comment'
                    placeholder='Введите комментарий'
                    no-resize
                    rows='5'
                    v-model='formData.comment'
                />
            </b-form-group>

            <div class='d-flex w-100 justify-content-end align-center mt-6'>
                <b-button type='button' variant='danger' class='mr-4' @click='closeModal'>Отменить</b-button>
                <b-button type='submit' variant='success'>Сохранить</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import VCalendar from 'v-calendar/lib/components/date-picker.umd';

export default {
    name: 'TeacherSalaryModal',
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    components: {
        VCalendar,
    },
    data() {
        return {
            loading: false,
            formData: {},
            payments: [
                {
                    text: 'Доплата',
                    value: 3,
                },
                {
                    text: 'Вычет',
                    value: 4,
                }
            ],
            masks: {
                input: 'DD.MM.YYYY',
            },
        };
    },
    async created() {
        for (const [k, v] of Object.entries(this.form)) {
            this.formData[k] = v;
        }
    },
    methods: {
        async onSubmit() {
            this.loading = true;

            try {
                await this.$axios.post(`/crm/teachers/${this.form.teacher_id}/teacher-salary/create-surcharge`, { ...this.formData });
                this.loading = false;

                this.$toasted.success('Доплата / вычет успешно добавлена', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });

                this.$emit('close', { refetch: true });
            } catch (ex) {
              console.log(ex);
                this.loading = false;

                let errMsg = 'Ошибка создания доплаты / вычета';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style lang='scss' scoped>
.teacher-salary-modal-date-close {
    position: absolute;
    right: 14px;
    top: 6px;
}
</style>