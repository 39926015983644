<template>
    <div class='teacher-dropdown'>
        <b-form-input
            class='dropdown-input'
            id='input-1'
            v-model='teacher'
            type='text'
            placeholder='Преподаватель'
            @input='handleInput'
            required
        ></b-form-input>
        <a v-show='out_teacher' class='reset-teacher' @click='clearPicked'>сброс</a>
        <div class='teacher-list' v-show='list && listVisible' @mouseover='listVisible = true' @mouseleave='listVisible = false'>
            <div class='list-item' v-for='item in list' :key='item.id'>
                <span class='item-name' >{{ item.full_name }}</span>
                <div class='item-info'>
                    <span class='info info-rating'>рейтинг: {{ item.rating.average|roundInt }}</span>
                    <span class='info info-votes'>голосов: {{ item.rating.votes }}</span>
                    <a class='info info-pick' @click='handlePick(item)'>выбрать</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeacherList',
    props: ['value'],
    data() {
        return {
            teacher: null,
            out_teacher: null,
            list: null,
            originList: null,
            filter: null,
            listVisible: false,
        };
    },
    filters: {
      roundInt(val) {
          return val.toFixed(2);
      }
    },
    watch: {
        teacher(newVal, oldVal) {
            if (!newVal) {
                this.list = this.originList
                return;
            }
            this.list = this.originList.filter(item => {
                const fullName = item.full_name.trim().replace(" ", '').toLowerCase()
                const finded = fullName.search(newVal.trim().replace(" ", '').toLowerCase());
                console.log(finded);
                return finded >= 0;
            })
        },
        out_teacher(newVal, oldVal) {
            this.$emit('input', newVal)
        }
    },
    methods: {
        async fetchTeacherList() {
            try {
                const { data } = await this.$axios.get('/api/rating', {
                    params: {
                        all: true,
                    },
                });
                this.list = data.data;
                this.originList = data.data;
            } catch (e) {
                this.$toasted.error(e, { position: 'bottom-right' });
            }
        },
        handleInput() {
            this.listVisible = true;
        },
        handlePick(val) {
            this.teacher = val.full_name;
            this.out_teacher = val
        },
        clearPicked() {
            this.out_teacher = null
            this.teacher = null
        }
    },
    async created() {
        await this.fetchTeacherList();
    },
};
</script>

<style scoped lang='scss'>
.teacher-dropdown {
    position: relative;
}
.item-info {
    display: flex;
}
.info {
    width: 85px;
    display: inline-block;
    text-align: right;
    color: #999;
    font-size: 12px;
    &-pick {
        font-size: 14px;
        width: 100px;
        background: #eee;
        padding: 0 5px;
        text-align: center;
        margin-left: 10px;
    }
}

.teacher-list {
    top: 40px;
    z-index: 10;
    left: 0;
    background: #fefefe;
    position: absolute;
    width: 100%;
    max-height: 350px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 4px 10px -8px #0006;
    overflow-y: auto;
}

.list-item {
    padding: 5px 15px;
    transition: .1s;
    display: flex;
    justify-content: space-between;

    &:hover {
        background: #eee;
    }
}
.reset-teacher {
    width: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    background: #eee;
    right: 7px;
    border-radius: 5px;
    color: #4d4d4d;
}
</style>