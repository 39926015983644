<template>
    <div>
        <h4 class='mb-4 page-title'>Новый отзыв</h4>
        <div>
            <b-form class='g-1 p-4' @submit.prevent='submitForm' @reset.prevent='resetForm'>
                <div class='widget row'>
                    <b-form-group
                        id='input-group-1'
                        label='ФИО преподавателя:'
                        class='col-6'
                        label-for='input-1'
                    >
                        <TeacherDropdown v-model='form.teacher'/>

                        <b-form-invalid-feedback>
                            Имя не может состоять из 2-х букв
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Клиент:'
                        class='col-6'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.customer'
                            type='text'
                            placeholder='Клиент'
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback>
                            Имя не может состоять из 2-х букв
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Возраст ребенка:'
                        class='col-3'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.child_age'
                            type='number'
                            min='1'
                            placeholder='Возраст ребенка'
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback>
                            Имя не может состоять из 2-х букв
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Оценка:'
                        class='col-3'
                        label-for='input-1'
                    >
                        <b-form-input
                            id='input-1'
                            v-model='form.mark'
                            type='number'
                            min='1'
                            max='5'
                            placeholder='Оценка'
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback>
                            Имя не может состоять из 2-х букв
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Локация:"
                        class="col-6"
                        label-for="input-1"
                    >
                      <b-form-select
                          class=""
                          :options="getLocationList"
                          :value="form.location"
                          v-model="form.location"
                          required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id='input-group-1'
                        label='Отзыв:'
                        class='col-12'
                        label-for='input-1'
                    >
                        <b-textarea
                            id='input-1'
                            v-model='form.review'
                            type='number'
                            maxlength='250'
                            placeholder='Отзыв'
                            required

                        ></b-textarea>
                        <b-form-invalid-feedback>
                            Имя не может состоять из 2-х букв
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <Switcher class='col-3' v-model='form.published' label='Опубликовано?'/>
                    <Switcher class='col-4' v-model='form.is_published_in_main' label='Опубликовано на главной?'/>
                </div>
                <b-form-group class='row justify-content-end'>
                    <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
                    <b-button type='reset' variant='primary'>Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteModal from '../../components/Modal/DeleteModal';
import Switcher from '../../components/Switcher';
import TeacherDropdown from '../../components/TeacherDropdown';

export default {
    name: 'ReviewsCreate',
    components: { Switcher, TeacherDropdown },
    data() {
        return {
            searchWord: null,
            originalData: null,
            form: {
                teacher: null,
                review: null,
                mark: null,
                location: null,
                customer: null,
                child_age: null,
                published: null,
                is_published_in_main: null,
            },
            locationList: null
        };
    },
    computed: {
        ...mapGetters({
            getLocationList: 'common/getLocationList',
            getList: 'reviews/getList',
        }),
    },
    watch: {
        searchWord(newVal, oldVal) {
            if (newVal === null || newVal === '') {
                this.fetchTeacherList();
            }
        },
    },
    methods: {
        statusBool(status) {
            return status.toLowerCase() === 'опубликовано';
        },
        getClass(status) {
            if (status === 'неопубликовано') return 'badge-noprofile';
            if (status === 'опубликовано') return 'badge-published';
            return 'badge-unknown';
        },
        ...mapActions({
            loadList: 'reviews/loadList',
        }),

        async fetchReview() {
            try {
                const { data } = await this.$axios.get(`/api/reviews/${this.$route.params.id}`);
                this.originalData = data.result;
                this.locationList = data.location_list;
                this.fillFormData();
            } catch (e) {
                this.$toasted.error(e, { position: 'bottom-right' });
                this.$router.back();
            }
        },

        fillFormData() {
            this.form.teacher_name = this.originalData.teacher;
            this.form.review = this.originalData.review;
            this.form.location = this.originalData.location;
            this.form.mark = this.originalData.mark;
            this.form.customer = this.originalData.customer;
            this.form.child_age = this.originalData.child_age;
            this.form.published = this.statusBool(this.originalData.published);
            this.form.is_published_in_main = this.statusBool(this.originalData.is_published_in_main);
        },

        async submitForm() {
            try {
                const {data} = await this.$axios.post(`/api/reviews/create`, {
                    'published': this.form.published,
                    'is_published_in_main': this.form.is_published_in_main,
                    'customer': this.form.customer,
                    'review': this.form.review,
                    location: this.form.location,
                    'mark': this.form.mark,
                    'child_age': this.form.child_age,
                    'teacher_id': this.form.teacher.id
                })
                this.originalData = data.result;
                this.fillFormData();
                this.$toasted.success("данные обновлены", {position: "bottom-right"})
            } catch (e) {
                this.$toasted.error(e, {position: "bottom-right"})
            }
        },

        resetForm() {
            this.$router.back();
        },
    },
    async created() {
        // await this.fetchReview();
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action {
    width: 100px;
}

.header-profile {
    width: 120px;
    text-align: center;
}

.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

@mixin badge($bg, $col) {
    background: $bg;
    color: $col;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.badge {
    &-noprofile {
        @include badge(#ee8c62, #fff);
    }

    &-published {
        @include badge(#70dba6, #000);
    }

    &-edit {
        @include badge(#faed5e, #000);
    }
}

.page-title {
    font-weight: bold;
}
</style>