<template>
  <div class="overlay">
    <div class="overlay__container">
      <div class="loadingio-spinner-bars-f0emewh36y">
        <div class="ldio-gnlg7mqlpx7">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p class="overlay__text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    text: {
      type: String,
      default: "загрузка"
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  //display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(#000, .5);

    &-small {
        position: absolute;
        width: 100%;
        height: 100%;
    }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    padding: 10px 15px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }

}

$opacityCol: .9;

@keyframes ldio-gnlg7mqlpx7 {
  0% {
    opacity: 1
  }
  //50% {
  //  opacity: 0
  //}
  100% {
    opacity: 0
  }
}

.ldio-gnlg7mqlpx7 div {
  position: absolute;
  width: 30px;
  height: 80px;
  top: 60px;
  animation: ldio-gnlg7mqlpx7 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.ldio-gnlg7mqlpx7 div:nth-child(1) {
  transform: translate(25px, 0);
  background: rgba(lighten(#002b49, 10), $opacityCol);
  animation-delay: -1s;
}

.ldio-gnlg7mqlpx7 div:nth-child(2) {
  transform: translate(65px, 0);
  background: rgba(lighten(#002b49, 20), $opacityCol);
  animation-delay: -0.6s;
}

.ldio-gnlg7mqlpx7 div:nth-child(3) {
  transform: translate(105px, 0);
  background: rgba(lighten(#002b49, 30), $opacityCol);
  animation-delay: -0.4s;
}

.ldio-gnlg7mqlpx7 div:nth-child(4) {
  transform: translate(145px, 0);
  background: rgba(lighten(#002b49, 35), $opacityCol);
  animation-delay: -0.2s;
}

.loadingio-spinner-bars-f0emewh36y {
  width: 200px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
}

.ldio-gnlg7mqlpx7 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-gnlg7mqlpx7 div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
</style>