<template>
    <div class="teacher__container">
        <b-tabs>
            <b-tab title="Основная информация" @click='handleTabClick'>
                <TeacherProfile/>
            </b-tab>
            <b-tab title="Профиль" @click='handleTabClick'>
                <TeacherProfileDetails/>
            </b-tab>
            <b-tab lazy title="Начисления" @click='handleTabClick'>
                <TeacherSalary/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import TeacherProfile from "./TeacherProfile";
import TeacherProfileDetails from "./TeacherProfileDetails";
import TeacherSalary from "./TeacherSalary";

export default {
  name: "TeacherProfileContainer",
  components: {TeacherProfile, TeacherProfileDetails, TeacherSalary},
    methods: {
      handleTabClick() {
          if (this.$route.query && Object.keys(this.$route.query).length > 0) {
              this.$router.replace({ query: {} });
          }

          return false;
      }
    }
}
</script>

<style scoped></style>