<template>
    <div class='teacher__details'>
        <h2>Профиль на сайте</h2>
        <div class='widget'>
            <b-form class='row g-1'>
                <div class='col-2'>
                    <b-form-group
                        label='Аватар'
                        class='col-12'
                        label-for='avatar'
                        @submit.prevent='handleSave'
                    >
                        <div class='d-flex flex-column justify-center'>
                            <img :src='fileUrl' alt='#' />

                            <avatar-cropper
                                uploadFileField='avatar'
                                v-model='showCropper'
                                :uploadUrl='`${baseUrl}/api/teacher/upload-avatar`'
                                @completed='handleCompleted'
                            />

                            <a @click.prevent='showCropper=true' class='btn btn-primary mt-2'><i
                                class='fa fa-image mr-2'></i>Загрузить
                                аватар</a>
                        </div>
                    </b-form-group>
                </div>
                <div class='col-10'>
                    <div class='row'>

                        <b-form-group
                            label="Локации:"
                            class="col-4"
                            label-for="input-language"
                        >
                          <Multiselect
                              id="input-language"
                              class="custom__multiselect"
                              selectLabel="нажмите чтобы выбрать"
                              deselectLabel="нажмите чтобы убрать"
                              placeholder="Выберите локацию"
                              v-model="form.locations"
                              :taggable="true"
                              :close-on-select="false"
                              :multiple="true"
                              :options="getLocationList"
                              :value="form.locations"
                              label='text'
                              track-by='value'
                          >
                          </Multiselect>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='Спортивное звание:'
                            class='col-4'
                            label-for='degree'
                        >
                            <b-form-select
                                id='degree'
                                type='text'
                                placeholder='например мастер спорта'
                                v-model='form.degree'
                                required
                            >
                                <b-form-select-option v-for='(value, key) in degreeList' :key='key' :value='key'>{{
                                        value
                                    }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='Стаж:'
                            class='col-4'
                            label-for='exp'
                        >
                            <b-form-input
                                id='exp'
                                type='number'
                                min='1'
                                placeholder='стаж'
                                v-model='form.experience'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='Страна:'
                            class='col-4'
                            label-for='country'
                        >
                            <b-form-select v-model='form.country'>
                                <b-form-select-option v-for='(value, key) in countries' :key='key' :value='value'>{{
                                        value
                                    }}
                                </b-form-select-option>
                            </b-form-select>
                            <!--                            <b-form-input-->
                            <!--                                id='country'-->
                            <!--                                type='text'-->
                            <!--                                v-model='form.country'-->
                            <!--                                placeholder='ссылка на шахматную доску'-->
                            <!--                                required-->
                            <!--                            ></b-form-input>-->
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='Ссылка на видеоприветсвие:'
                            class='col-8'
                            label-for='videourl'
                        >
                            <b-form-input
                                id='videourl'
                                type='text'
                                v-model='form.video_url'
                                placeholder='ссылка на видеоприветствие'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>


                        <b-form-group
                            id='input-group-1'
                            label='Возраст обучения от:'
                            class='col-3'
                            label-for='agefrom'
                        >
                            <b-form-input
                                id='agefrom'
                                type='text'
                                v-model='form.age_from'
                                placeholder='например 5'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>


                        <b-form-group
                            id='input-group-1'
                            label='Возраст обучения до:'
                            class='col-3'
                            label-for='ageto'
                        >
                            <b-form-input
                                id='ageto'
                                type='text'
                                v-model='form.age_to'
                                placeholder='например 12'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='О себе:'
                            class='col-12'
                            label-for='descr'
                        >
                            <b-form-textarea
                                id='descr'
                                type='text'
                                v-model='form.description'
                                placeholder='О себе'
                                required
                            ></b-form-textarea>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id='input-group-1'
                            label='О себе на английском:'
                            class='col-12'
                            label-for='descr_en'
                        >
                            <b-form-textarea
                                id='descr_en'
                                type='text'
                                v-model='form.description_en'
                                placeholder='о себе но английском'
                                required
                            ></b-form-textarea>
                            <b-form-invalid-feedback>
                                Имя не может состоять из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            label='Опубликовать?'
                            class='form__group col-12 text-right d-flex align-items-center'
                            label-for='publish'
                        >
                            <input class='ml-1' type='checkbox' id='publish' v-model='form.published'>
                        </b-form-group>

                    </div>
                    <div class='row justify-content-end p-4'>
                        <button type='submit' @click.prevent='handleSave' class='btn btn-success'>Сохранить</button>
                    </div>
                </div>
            </b-form>
        </div>

    </div>
</template>

<script>
// import AvatarCropper from 'vue-avatar-cropper/src/vue-avatar-cropper';
import AvatarCropper from '@/components/AvatarCropper/AvatarCropper';
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
    name: 'TeacherProfileDetails.vue',
    components: { AvatarCropper, Multiselect },
    data() {
        return {
            teacherId: this.$route.params.id,
            showCropper: false,
            fileUrl: 'https://place-hold.it/200x200',
            thumbUrl: 'https://place-hold.it/200x200',
            form: {
                degree: null,
                experience: null,
                country: null,
                video_url: null,
                age_from: null,
                locations: [],
                age_to: null,
                description: this.fileUrl,
                published: false,
                description_en: null,
            },
            fileName: 'unknown',
        };
    },
    async created() {
        if (this.teacherId) {
            await this.loadUserProfile(this.teacherId);
        }
    },
    computed: {
        ...mapGetters({
            getLocationList: 'common/getLocationList',
            degreeList: 'common/getTeacherDegree',
            countries: 'common/getCountries',
        }),
        baseUrl() {
            return process.env.VUE_APP_API_BASE_URL;
        },
    },
    methods: {
        async loadUserProfile(userId) {
            const { data } = await this.$axios.get(`/api/teacher/profile/${userId}`);
            if (data && !data.error && data.profile) {
                const profile = data.profile;
                this.form.degree = profile.degree;
                this.form.experience = profile.experience;
                this.form.description = profile.description;
                this.form.description_en = profile.description_en;
                this.form.country = profile.country;
                this.form.locations = profile.locations;
                this.form.video_url = profile.video_url;
                this.form.rating = profile.rating;
                this.form.age_from = profile.age_category.split(',')[0];
                this.form.age_to = profile.age_category.split(',')[1];
                // this.form.published = profile.status;
                this.fileUrl = profile.avatar_url;
                this.thumbUrl = profile.avatar_thumb;
            }
        },
        async handleUpload(form, response) {
        },
        handleFileChange({ file, reader }) {
            this.fileName = file.name || 'unknown';
        },
        async handleCompleted({ form, request, response }) {
            const data = response.data;
            if (data.error) {
                alert('произошла ошибка при загрузке');
            }
            this.fileUrl = data.relative_url;
            this.thumbUrl = data.thumb_url;
        },
        async updateProfile() {
            return await this.$axios.post(`/api/teacher/profile`, {
                ...this.form,
                teacher_id: this.teacherId,
                avatar_url: this.fileUrl,
                avatar_thumb: this.thumbUrl,
            });
        },
        async handleSave() {
            const { data } = await this.updateProfile();

            if (!this.isFormValid()) return
            if (data.success) {
                this.$toasted.success('Данные сохранены', { position: 'bottom-right' });
            } else if (data.error) {
                this.$toasted.error(Object.keys(data.errors).map(item => data.errors[item]).join('\n'), { position: 'bottom-right' });
            }
        },
        isFormValid() {
          switch (true) {
            case this.form.locations.length === 0:
              this.$toasted.error('Необходимо заполнить поле "локации"', { position: 'bottom-right' })
              return false;
            case this.form.degree === null:
              this.$toasted.error('Необходимо заполнить поле "спортивное звание"', { position: 'bottom-right' })
              return false;
            case this.form.experience === "" || this.form.experience === null:
              this.$toasted.error('Необходимо заполнить поле "стаж"', { position: 'bottom-right' })
              return false;
            case this.form.country === "" || this.form.country === null:
              this.$toasted.error('Необходимо заполнить поле "страна"', { position: 'bottom-right' })
              return false;
            case this.form.video_url === "" || this.form.video_url === null:
              this.$toasted.error('Необходимо заполнить поле "ссылка на видеоприветсвие"', { position: 'bottom-right' })
              return false;
            case this.form.age_from === "" || this.form.age_from === null:
              this.$toasted.error('Необходимо заполнить поле "возраст обучения от"', { position: 'bottom-right' })
              return false;
            case this.form.age_to === "" || this.form.age_to === null:
              this.$toasted.error('Необходимо заполнить поле "возраст обучения до"', { position: 'bottom-right' })
              return false;
            case !this.form.description && !this.form.description_en:
              this.$toasted.error('\'Необходимо заполнить поле "о себе" или "о себе на английском"', { position: 'bottom-right' })
              return false;
          }

          return true;
        },
    },

};
</script>

<style lang='scss' scoped>
::v-deep {
    .form__group {
        justify-content: flex-end;

        label {
            margin: 0;
        }
    }
}
</style>