<template>
    <div class='teacher-salary-modal bg-gray-100'>
        <Preloader v-if='loading' class='overlay-small' />

        <h3 class='d-block p-4 mb-4 border-bottom border-gray'>
            <template v-if='formData.teacher_name'>{{ formData.teacher_name }}</template>
        </h3>

        <div class='px-4 pb-4 mb-4 border-bottom border-gray'>
            <div class='row'>
                <b-form-group class='col-6' label='Дата:' label-for='tsmp-date'>
                    <VCalendar
                        mode='date'
                        is-range
                        :popover="{ visibility: 'focus' }"
                        :masks="{ input: ['DD.MM.YYYY']}"
                        @input='handleDateSelect'
                        v-model='formData.date'
                    >
                        <template v-slot='{ togglePopover }'>
                            <div @click='togglePopover()'>
                                <input
                                    class='form-control'
                                    :class="{'hidden': !formData.date.start}"
                                    :value='`с ${formatDate(formData.date.start)} по ${formatDate(formData.date.end)}`'
                                    readonly
                                />
                            </div>
                        </template>
                    </VCalendar>
                </b-form-group>

                <b-form-group class='col-6'>
                    <label label-for='tsmp-total'>
                        <span class='mr-2'>Сумма оплаты:</span>
                        <span class="total-example">
                            <span>{{ getTotalInfo }} <span class="text">(начисление)</span></span>
                            <span v-if="usePenalty && currentPenalty > 0"> - {{ currentPenalty }} <span class="text">(штраф)</span></span>
                        </span>
                    </label>

                    <input
                        id='tsmp-total'
                        class='form-control'
                        :class="{ 'is-invalid': salaryNegative }"
                        min='0'
                        type='number'
                        placeholder='Сумма оплаты'
                        required
                        :value='salary'
                        @input='handleSalaryUpdate($event)'
                    />

                    <div class='d-flex items-center my-2'>
                        <b-form-checkbox
                            name="penalty"
                            @input='toggleUsePenalty'
                            v-model="usePenalty"
                        >
                            Включая штрафы
                        </b-form-checkbox>
                    </div>
                </b-form-group>
            </div>
        </div>

        <div class="d-flex justify-content-end ml-auto">
            <TeacherSalariesTotal v-if="this.total" :total="this.total"/>
        </div>

        <div class='teacher-salary-modal-content'>
            <b-tabs content-class=''>
                <b-tab title='Начисления' active @click='() => visibleStudentRows = []'>
                    <div class='tsmp-students'>
                        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
                            <div>Ученик</div>
                            <div class='text-center'>Занятий</div>
                            <div class='text-center'>Задолженность</div>
                            <div>Выплата</div>
                            <div></div>
                        </div>

                        <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
                            <div
                                v-for='(student, idx) in students'
                                :key='student.id'
                                class='tsmp-students-body-row border-bottom border-gray'
                                :class="{ 'active': isStudentRowVisible(idx) }"
                                @mousedown='toggleStudentRowVisibility(idx)'
                            >
                                <div class='tsmp-students-body-grid'>
                                    <div>
                                        {{ student.name }}
                                        <span class='ml-2 fs-5'>
                                            <i
                                                class='fa fa-check-circle'
                                                :class="student.approved ? 'text-primary' : 'text-secondary opacity-50'"
                                                :title="student.approved ? 'Платеж подтвержден' : 'Не все платежи подтверждены'"
                                            ></i>
                                        </span>
                                    </div>
                                    <div class='text-center'>{{ student.lesson_count }}</div>
                                    <div class='text-center'>{{ parseInt(student.amount) }}</div>
                                    <div class='d-flex align-items-center' @mousedown.stop>
                                        <input
                                            type='number'
                                            class='form-control'
                                            placeholder='Введите сумму'
                                            required
                                            min='0'
                                            @input='handleStudentUpdate($event, idx)'
                                            v-model='formDataStudents[idx].payment'
                                        />
                                    </div>
                                    <div class='text-center tsmp-students-body-row-icon'>
                                        <a @click.prevent='() => false'>
                                            <i class='fa fa-angle-down'></i>
                                        </a>
                                    </div>
                                </div>

                                <div
                                    v-show='isStudentRowVisible(idx)'
                                    class='tsmp-collapse w-100 bg-white border-gray'
                                    @click.stop='() => false'
                                >
                                    <table class='table table-hover mb-0'>
                                        <thead class='table_head'>
                                        <tr>
                                            <th>№</th>
                                            <th>Тип</th>
                                            <th>Сумма</th>
                                            <th class='text-center'>Подтвержден</th>
                                            <th class='text-center'>Создан</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for='salary in student.salaries' :key='salary.id'>
                                            <td>{{ salary.id }}</td>
                                            <td @mousedown.stop>
                                                <template
                                                    v-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'user'"
                                                >
                                                    <a
                                                        href='#'
                                                        @click.prevent='handleLinkClick(`/users/${salary.creator.id}`)'
                                                    >
                                                        {{ salary.title }}
                                                    </a>
                                                </template>
                                                <template
                                                    v-else-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'transaction'"
                                                >
                                                    <a
                                                        href='#'
                                                        @click.prevent='handleLinkClick(`/transactions-edit/${salary.creator.id}`)'
                                                    >
                                                        {{ salary.title }}
                                                    </a>
                                                </template>
                                                <template v-else-if='salary.creator'>
                                                    {{ salary.title }}
                                                </template>
                                            </td>
                                            <td
                                                :class="{ 'text-success': salary.operation === 1, 'text-danger': salary.operation !== 1 }"
                                            >
                                                {{ salary.operation === 1 ? '+' : '-' }}
                                                {{ parseInt(salary.amount) }} ({{ salary.currency }})
                                            </td>
                                            <td class='text-center fs-5'>
                                                <i class='fa fa-check-circle'
                                                   :class="salary.approved ? 'text-primary' : 'text-secondary opacity-50'"></i>
                                            </td>
                                            <td class='text-center'>{{ salary.date }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab title='Доплата / Штрафы ' @click='() => visibleStudentRows = []'>
                    <div class='tsmp-students penalties'>
                        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
                            <div>Создано</div>
                            <div class='text-center'>Сумма</div>
                            <div class='tsmp-comment'>Комментарий</div>
                            <div class='text-center'>Подтвержден</div>
                            <div class='text-center'>Создан</div>
                        </div>

                        <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
                            <div
                                v-for='(penalty, penaltyIdx) in penalties'
                                :key='penalty.id'
                                class='tsmp-students-body-row border-bottom border-gray'
                            >
                                <div class='tsmp-students-body-grid'>
                                    <div @mousedown.stop>
                                        <template
                                            v-if="$store.getters['user/getRole'] === 'admin' && penalty.creator && penalty.creator.type === 'user'"
                                        >
                                            <a
                                                href='#'
                                                @click.prevent='handleLinkClick(`/users/${penalty.creator.id}`)'
                                            >
                                                {{ penalty.title }}
                                            </a>
                                        </template>
                                        <template
                                            v-else-if="$store.getters['user/getRole'] === 'admin' && penalty.creator && penalty.creator.type === 'transaction'"
                                        >
                                            <a
                                                href='#'
                                                @click.prevent='handleLinkClick(`/transactions-edit/${penalty.creator.id}`)'
                                            >
                                                {{ penalty.title }}
                                            </a>
                                        </template>
                                        <template v-else-if='penalty.creator'>
                                            {{ penalty.title }}
                                        </template>
                                    </div>
                                    <td
                                        class='text-center'
                                        :class="{ 'text-success': penalty.operation === 1, 'text-danger': penalty.operation !== 1 }"
                                    >
                                        <span>
                                            {{ penalty.operation === 1 ? '+' : '-' }}
                                            {{ parseInt(penalty.amount) }} ({{ penalty.currency }})
                                        </span>

                                        <input
                                            v-if="penaltyTypes[penalty.payment_type] === 'bonus'"
                                            type='number'
                                            class='form-control mt-1'
                                            placeholder='Введите сумму'
                                            required
                                            min='0'
                                            @input='handleBonusUpdate($event, penaltyIdx)'
                                            v-model='penalties[penaltyIdx].payment'
                                        />
                                    </td>
                                    <div class='tsmp-comment'>{{ penalty.comment }}</div>
                                    <div class='text-center fs-5'>
                                        <i
                                            class='fa fa-check-circle'
                                            :class="penalty.approved ? 'text-primary' : 'text-secondary opacity-50'"
                                        ></i>
                                    </div>
                                    <div class='text-center'>{{ penalty.date }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab title='Выплаты' @click='() => visibleStudentRows = []'>
                  <div class='tsmp-students payments'>
                    <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
                      <div>Создано</div>
                      <div class='text-center'>Сумма</div>
                      <div class='text-center'>Создан</div>
                      <div class='text-center'>Статус</div>
                    </div>

                      <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
                          <div
                              v-for='(payment) in payments'
                              :key='payment.id'
                              class='tsmp-students-body-row border-bottom border-gray'
                          >
                              <div class='tsmp-students-body-grid'>
                                  <div @mousedown.stop>
                                      <template
                                          v-if="$store.getters['user/getRole'] === 'admin'"
                                      >
                                          <router-link :to='`/users/${payment.creator_id}`'>
                                              {{ payment.title }}
                                          </router-link>
                                      </template>
                                      <template v-else>
                                          {{ payment.title }}
                                      </template>
                                  </div>
                                  <td class='text-center text-success'>
                                      {{ parseInt(payment.amount) }} ({{ payment.currency }})
                                  </td>
                                  <div class='text-center'>{{ payment.date }}</div>
                                  <div class='text-center fs-5'>
                                      <i
                                          class='fa'
                                          :class="payment.canceled ? 'text-secondary opacity-50 fa-close' : 'text-success fa-check-circle'"
                                      ></i>
                                  </div>
                              </div>
                          </div>
                    </div>
                  </div>
                </b-tab>
            </b-tabs>
        </div>

        <b-form class='p-4' @submit.prevent='handleSubmit'>
            <div class='d-flex w-100 justify-content-end align-center mt-6'>
                <b-button type='button' variant='danger' class='mr-4' @click='closeModal'>Отменить</b-button>
                <b-button
                    :class='{ disabled: salaryNegative }'
                    :disabled='salaryNegative'
                    type='submit'
                    variant='success'
                >
                    Создать
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import TeacherSalariesTotal from '@/components/admin/teacher-salary/TeacherSalariesTotal';
import Preloader from '@/components/Preloader.vue';

export default {
    name: 'TeacherSalaryModalPay',
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    components: {
        Preloader,
        VCalendar,
        TeacherSalariesTotal
    },
    data() {
        return {
            loading: false,
            formData: {},
            penalties: null,
            payments: null,
            students: null,
            formDataStudents: null, // тут изменяем цены
            visibleStudentRows: [],
            total: null,
            salary: 0,
            totalSalary: 0,
            maxSalary: 0,
            penalty: 0,
            usePenalty: true,
            penaltyTypes: {
                3: 'bonus',
                4: 'penalty',
            },
        };
    },
    async created() {
        for (const [k, v] of Object.entries(this.form)) {
            this.formData[k] = v;
        }

        await this.dataFetching();
    },
    computed: {
        dayjs() {
            return dayjs;
        },
        isStudentRowVisible() {
            return (idx) => this.visibleStudentRows.includes(idx);
        },
        getTotalInfo() {
            let res = this.salary;

            if (this.usePenalty) {
                res = Number(res) + Number(this.currentPenalty);
            }

            return res;
        },
        currentPenalty() {
            let penalty = 0;

            if (this.penalties && this.penalties.length) {
                this.penalties.forEach((element) => {
                    if (this.penaltyTypes[element.payment_type] === 'penalty') {
                        penalty += Number(element.amount_debt);
                    }
                });
            }

            return penalty;
        },
        salaryNegative() {
            return this.salary < 0;
        },
    },
    methods: {
        async handleSubmit() {
            this.loading = true;
            const students = this.mapStudents();
            const bonuses = this.mapBonuses();

            try {
                await this.$axios.post(`/crm/teachers/${this.formData.teacher_id}/teacher-salary/create-pay`, {
                  date_from: dayjs(this.formData.date.start).format('YYYY-MM-DD'),
                  date_to: dayjs(this.formData.date.end).format('YYYY-MM-DD'),
                  amount: this.salary,
                  salaries: students.concat(bonuses),
                  usePenalty: this.usePenalty,
                });
                this.loading = false;
                this.$toasted.success('Выплата успешно добавлена', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
                this.$emit('close', { refetch: true });
            } catch (ex) {
                this.loading = false;
                console.log('cant send teacher salary info: ', ex);
                let errMsg = 'Ошибка создания выплаты';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
        async dataFetching() {
            this.loading = true;
            await Promise.all([
                this.fetchTotal(),
                this.fetchStudents(),
                this.fetchPenalties(),
                this.fetchPayments(),
            ]);

            this.salary = this.calculateSalaryOnInit();
            this.maxSalary = this.salary;
            this.loading = false;
        },
        calculateSalaryOnInit() {
            let sum = 0;

            if (this.formDataStudents && this.formDataStudents.length) {
                sum = this.formDataStudents.reduce((prev, current) => Number(prev) + Number(current.debt), 0);
            }

            if (this.penalties && this.penalties.length) {
                this.penalties.forEach((penalty) => {
                    if (this.penaltyTypes[penalty.payment_type] === 'bonus') {
                        sum += Number(penalty.amount_debt);
                    }
                });
            }

            return this.usePenalty ? Number(sum) - this.currentPenalty : Number(sum);
        },
        async handleDateSelect() {
            await this.dataFetching();
        },
        async fetchStudents() {
            try {
                const { data } = await this.$axios.get(`crm/teachers/${this.formData.teacher_id}/teacher-salary`, { params: {
                    date_from: this.formData.date.start,
                    date_to: this.formData.date.end,
                    paid: false,
                  }
                });

                this.students = data.data; // оригинал

                this.formDataStudents = JSON.parse(JSON.stringify(data.data));
                this.formDataStudents.forEach(student => { student.payment = student.debt });
            } catch (ex) {
                console.log('cant fetch students: ', ex);
            }
        },
        async fetchPenalties() {
            try {
                const { data } = await this.$axios.get(`crm/teachers/${this.formData.teacher_id}/teacher-salary-list`, { params: {
                        date_from: this.formData.date.start,
                        date_to: this.formData.date.end,
                        paid: false,
                        types: [3, 4],
                        limit: 1000,
                    }
                });
                this.penalties = data.data;

                if (this.penalties && this.penalties.length) {
                    this.penalties.forEach((element) => {
                        element.payment = element.amount_debt;
                    });
                }
            } catch (ex) {
                console.log('cant fetch penalties: ', ex);
            }
        },
        async fetchPayments() {
            try {
                const { data } = await this.$axios.get(`crm/teachers/${this.formData.teacher_id}/teacher-payouts`, {
                    params: {
                        date_from: this.formData.date.start,
                        date_to: this.formData.date.end,
                    }
                });
                this.payments = data.data;
            } catch (ex) {
                console.log('cant fetch payments: ', ex);
            }
        },
        async fetchTotal() {
            try {
                const {data} = await this.$axios.get(`crm/teachers/teacher-salary/total`, {params: {
                        date_from: this.formData.date.start,
                        date_to: this.formData.date.end,
                        teacher_id: this.formData.teacher_id,
                    }});
                this.total = data;
            } catch (ex) {
                console.log('cant fetch teacher salary total: ' + ex);
            }
        },
        closeModal() {
            this.$modal.hideAll();
        },
        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY');
        },
        toggleStudentRowVisibility(rowIdx, e) {
            if (this.isStudentRowVisible(rowIdx)) {
                this.visibleStudentRows = this.visibleStudentRows.filter(row => row !== rowIdx);
            } else {
                this.visibleStudentRows.push(rowIdx);
            }
        },
        handleSalaryUpdate(e) {
            let val = e.target.value;

            // валидации
            if (val < 0 || val === '' || val === ' ') {
                e.target.value = 0;
                this.salary = 0;
                return false;
            }

            if (val > this.maxSalary) {
                e.target.value = this.maxSalary;
                this.salary = this.maxSalary;
                this.calculateSalary(this.maxSalary);
                return false;
            }

            this.salary = val;
            this.calculateSalary(val);
        },
        handleStudentUpdate(e, idx) {
            let val = e.target.value;
            let sum = 0;

            // валидации
            if (val < 0 || val === '' || val === ' ') {
                e.target.value = 0;
                this.$set(this.formDataStudents, idx, { ...this.formDataStudents[idx], ...{ payment: 0 } });
            }

            // max check
            if (val > this.formDataStudents[idx].debt) {
                e.target.value = this.formDataStudents[idx].debt;
                this.$set(this.formDataStudents, idx, {
                    ...this.formDataStudents[idx],
                    ...{ payment: this.formDataStudents[idx].debt }
                });
            }

            // подсчет студентов
            if (this.formDataStudents && this.formDataStudents.length) {
                sum = this.formDataStudents.reduce((prev, current) => Number(prev) + Number(current.payment), 0);
            }

            // подсчет бонусов
            if (this.penalties && this.penalties.length) {
                this.penalties.forEach((item) => {
                    const bonus = this.penaltyTypes[item.payment_type] === 'bonus' ? Number(item.payment) : 0;
                    sum = Number(sum) + bonus;
                });
            }

            // учет штрафов
            if (this.usePenalty) {
                sum = Number(sum) - Number(this.currentPenalty);
            }

            this.salary = sum;
        },
        handleBonusUpdate(e, idx) {
            let val = e.target.value;
            let sum = 0;

            // валидации
            if (val < 0 || val === '' || val === ' ') {
                e.target.value = 0;
                this.$set(this.penalties, idx, { ...this.penalties[idx], ...{ payment: 0 } });
            }

            // max check
            if (val > this.penalties[idx].amount_debt) {
                e.target.value = this.penalties[idx].amount_debt;
                this.$set(this.penalties, idx, {
                    ...this.penalties[idx],
                    ...{ payment: this.penalties[idx].amount_debt }
                });
            }

            // подсчет студентов
            if (this.formDataStudents && this.formDataStudents.length) {
                sum = this.formDataStudents.reduce((prev, current) => Number(prev) + Number(current.payment), 0);
            }

            // подсчет бонусов
            if (this.penalties && this.penalties.length) {
                this.penalties.forEach((item) => {
                    const bonus = this.penaltyTypes[item.payment_type] === 'bonus' ? Number(item.payment) : 0;
                    sum = Number(sum) + bonus;
                });
            }

            // учет штрафов
            if (this.usePenalty) {
                sum = Number(sum) - Number(this.currentPenalty);
            }

            this.salary = sum;
        },
        toggleUsePenalty() {
            // запускаем перерасчет
            this.maxSalary = this.calculateSalaryOnInit();
            if (this.salary > this.maxSalary) this.salary = this.maxSalary;
            this.calculateSalary(this.salary);
        },
        calculateSalary(val) {
            let amount = Number(val);

            if (this.usePenalty) {
                amount = Number(amount) + Number(this.currentPenalty);
            } else if (!this.usePenalty && amount < 0) {
                // на случай переключения тоглера со штрафом и отрицательным начислением
                amount = amount + this.currentPenalty;
                this.salary = amount;
            }

            // перерасчет по студентам
            this.formDataStudents.forEach((item) => {
                if (amount >= item.debt) {
                    amount = Number(amount) - Number(item.debt);
                    item.payment = item.debt;
                } else if (amount < item.debt) {
                    item.payment = amount;
                    amount = 0;
                }
            });

            // перерасчет по доплатам
            if (this.penalties && this.penalties.length) {
                this.penalties.forEach((item) => {
                    if (this.penaltyTypes[item.payment_type] === 'bonus') {
                        if (amount >= item.amount_debt) {
                            amount = Number(amount) - Number(item.amount_debt);
                            item.payment = item.amount_debt;
                        } else if (amount < item.amount_debt) {
                            item.payment = amount;
                            amount = 0;
                        }
                    }
                });
            }
        },
        mapBonuses() {
            const bonuses = [];

            if (this.penalties && this.penalties.length) {
                for (let i = 0; i < this.penalties.length; i++) {
                    const penalty = this.penalties[i];

                    if (this.penaltyTypes[penalty.payment_type] === 'penalty' || Number(penalty.payment) === 0) continue;
                    bonuses.push({
                        id: penalty.id,
                        payment: penalty.payment,
                    });
                }
            }

            return bonuses;
        },
        mapStudents() {
            const res = [];

            if (this.formDataStudents && this.formDataStudents.length) {
                this.formDataStudents.forEach(student => {
                    let amount = student.payment;

                    if (amount > 0) {
                        for (let i = 0; i < student.salaries.length; i++) {
                            const salary = student.salaries[i];

                            if (amount >= salary.amount_debt) {
                                amount = Number(amount) - Number(salary.amount_debt);
                                res.push({
                                    id: salary.id,
                                    payment: salary.amount_debt,
                                });
                            } else if (amount > 0 && amount < salary.amount_debt) {
                                res.push({
                                    id: salary.id,
                                    payment: amount,
                                });
                                amount = 0;
                            } else {
                                break;
                            }
                        }
                    }
                });
            }

            return res;
        },
        handleLinkClick(link) {
            this.closeModal();
            this.$router.push(link);
        },
    },
};
</script>

<style lang='scss' scoped>
.is-invalid {
    border-color: #FD5F00 !important;
}

.bg-gray-100 {
    background-color: #f9fbfd;
}

.teacher-salary-modal-content {
    max-height: 440px;
    padding: 8px 20px 16px;
    overflow: hidden;
}

.tsmp-scrollbar {
    &::-webkit-scrollbar-track {
        border-radius: 20px;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: rgba(#1B3C66, 0.2);
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
        height: 58px;
        background-color: #498BDF;
        border-radius: 20px;
    }
}

.tsmp-students-table-scrollbar {
    position: relative;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    max-height: 325px;
    overflow-y: auto;
}

.tsmp-students-header,
.tsmp-students-body-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 52px;
    align-items: center;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.penalties {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(5, 1fr);

        & > * {
            padding: 12px 20px;
        }
    }
}

.payments {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(3, 1fr) 108px;

        & > * {
            padding: 12px 20px;
        }
    }
}

.tsmp-students-header {
    margin-left: -20px;
    margin-right: -20px;
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    background-color: rgba(#798892, 0.05);

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-body-row-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    min-height: 58px;
}

.tsmp-collapse {
    cursor: default;

    thead {
        font-size: 12px;
    }
}

.tsmp-comment {
    width: 220px;
}

.tab-content > .tab-pane {
    padding: 0 20px;
}

.total-example {
    padding-top: 5px;
    color: #6B86A3;

    .text {
        font-size: 11px;
    }
}
</style>