<template>
  <div>
    <h4>Создание нового преподавателя</h4>
    <div class='widget'>
      <b-form class='row g-1' @submit.prevent='save'>

        <!-- Основная информация -->
        <b-form-group
            id='input-group-1'
            label='ФИО:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.full_name'
              type='text'
              placeholder='ФИО'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Пол:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-select
              id='inline-form-custom-select-pref'
              class=''
              :options='getGenderList'
              :value='form.gender'
              v-model='form.gender'
          ></b-form-select>
        </b-form-group>
        <b-form-group
            v-if="getLanguageList"
            id="input-group-lang"
            label="Язык преподавания:"
            class="col-6"
            label-for="input-lang"
        >
          <b-form-select
              class=""
              :options="getLanguageList"
              :value="form.language"
              v-model="form.language"
              required
          ></b-form-select>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Телефон:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.phone'
              type='text'
              v-mask="'####################'"
              placeholder='телефон'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Почта:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-input
              id='input-1'
              v-model='form.email'
              type='text'
              placeholder='email'
              required
          ></b-form-input>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Zoom:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-textarea
              id='input-1'
              v-model='form.zoom_url'
              type='text'
              placeholder='ссылка зум'
          ></b-form-textarea>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='zoom'
            label='Данные по Zoom:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-group
              id='zoom-login-form'
              label='Логин'
              class='col-6'
              label-for='login-zoom'
          >
            <b-form-input
                id='login-zoom'
                v-model='form.login_zoom'
                type='text'
                placeholder='Логин пользователя zoom'
            />
          </b-form-group>
          <b-form-group
              id='zoom-login-form'
              label='Пароль'
              class='col-6'
              label-for='password-zoom'
          >
            <b-form-input
                id='password-zoom'
                v-model='form.password_zoom'
                type='text'
                placeholder='Пароль пользователя zoom'
            />
          </b-form-group>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Ссылка на шахматную доску:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-textarea
              id='input-1'
              v-model='form.chess_board_url'
              type='text'
              placeholder='ссылка на шахматную доску'
              disabled
          ></b-form-textarea>
          <b-form-invalid-feedback>
            Имя не может состоять из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id='input-group-1'
            label='Данные шахматной доски:'
            class='col-6'
            label-for='input-1'
        >
          <b-form-group label='Имя пользователя' label-for='li_nick'>
            <b-form-input
                id='li_nick'
                v-model='form.lichess_username'
                type='text'
                placeholder='Имя пользователя в шахматной доске'
            ></b-form-input>
          </b-form-group>

          <b-form-group label='Логин Тестового пользователя' label-for='li_login'>
            <b-form-input
                id='li_login'
                v-model='form.lichess_login'
                type='text'
                placeholder='логин в шахматной доске'
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback>
            логин обязателен
          </b-form-invalid-feedback>

          <b-form-group label='Пароль Тестового пользователя' label-for='li_pwd'>
            <b-form-input
                id='li_pwd'
                v-model='form.lichess_password'
                type='text'
                placeholder='пароль от шахматной доски'
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback>
            пароль обязателен
          </b-form-invalid-feedback>

          <b-form-group label='Ссылка на комнату для пробных уроков' label-for='li_room_url'>
            <b-input-group class='lichess-room-url-input-group'>
              <b-form-input
                  id='li_room_url'
                  v-model='form.lichess_room_url'
                  type='text'
                  placeholder='Ссылка на комнату'
                  ref='lichessRoomUrl'
              ></b-form-input>
              <b-input-group-append>
                <b-button variant='info' @click.prevent="(e) => copyLink(e, 'lichessRoomUrl')">Скопировать</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form-group>
        <b-form-group
            class='col-6'
            label='Профиль:'
            id='input-group-2'
            label-for='input-2'>
          <b-form-group
              id='input-group-1'
              label='Категория начислений:'
              class='col-12'
              label-for='country'
          >
<!--            <b-form-select v-model='form.salaryCategory'>-->
<!--              <b-form-select-option v-for='(value, key) in categoryList' :key='key' :value='value'>{{-->
<!--                  value-->
<!--                }}-->
<!--              </b-form-select-option>-->
<!--            </b-form-select>-->
            <b-form-select
                :options="[{value: null, text: 'Выберите категорию', disabled: true}, ...categoryList]"
                v-model='form.salary_category'
                required
            />
            <b-form-invalid-feedback>
              Имя не может состоять из 2-х букв
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              id='input-group-1'
              label='Страна:'
              class='col-12'
              label-for='country'
          >
            <b-form-select v-model='form.country' required>
              <b-form-select-option v-for='(value, key) in countries' :key='key' :value='value'>{{
                  value
                }}
              </b-form-select-option>
            </b-form-select>
            <!--                            <b-form-input-->
            <!--                                id='country'-->
            <!--                                type='text'-->
            <!--                                v-model='form.country'-->
            <!--                                placeholder='ссылка на шахматную доску'-->
            <!--                                required-->
            <!--                            ></b-form-input>-->
            <b-form-invalid-feedback>
              Имя не может состоять из 2-х букв
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              label="Локации:"
              class="col-12"
              label-for="input-location"
          >
            <Multiselect
                id="input-location"
                class="custom__multiselect"
                :class="{'custom__multiselect-error': !formValidate.isValidLocation}"
                @select="() => {this.formValidate.isValidLocation = true}"
                selectLabel="нажмите чтобы выбрать"
                deselectLabel="нажмите чтобы убрать"
                placeholder="Выберите локацию"
                v-model="form.locations"
                :taggable="true"
                :close-on-select="false"
                :multiple="true"
                :options="getLocationList"
                :value="form.locations"
                label='text'
                track-by='value'
            >
            </Multiselect>
          </b-form-group>
        </b-form-group>


        <b-form-group class='col-12 text-right'>
          <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
          <b-button type='button' @click.prevent="$router.push({ name: 'TeacherList' })" variant='primary'>
            Назад
          </b-button>
        </b-form-group>

      </b-form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Multiselect from "vue-multiselect";

export default {
  name: 'TeacherCreate',
  components: {Multiselect},
  data() {
    return {
      isSendingEmail: false,
      categoryList: [],
      form: {
          full_name: null,
          phone: null,
          email: null,
          zoom_url: null,
          chess_board_url: null,
          gender: null,
          login_zoom: null,
          password_zoom: null,
          language: null,
          lichess_username: null,
          lichess_login: null,
          lichess_password: null,
          lichess_room_url: null,
          country: null,
          locations: [],
          salary_category: null,
          avatar_thumb: 'https://place-hold.it/200x200',
          avatar_url: 'https://place-hold.it/200x200'
        },
        formValidate: {
          isValidLocation: true,
        }
      };
  },
  computed: {
    ...mapGetters({
      getLocationList: 'common/getLocationList',
      degreeList: 'common/getTeacherDegree',
      countries: 'common/getCountries',
      getTeacherUserDetail: 'teacher/getTeacherUserDetail',
      getGenderList: 'common/getGenderList',
      getLanguageList: 'common/getLanguageList',
      getAccessLink: 'teacher/getAccessLink',
      getRole: 'user/getRole',
    }),
  },
  methods: {
    ...mapActions({
      fetchTeacherDetails: 'teacher/fetchTeacherDetails',
      updateTeacherDetails: 'teacher/updateTeacherDetails',
      createTeacher: 'teacher/createTeacher',
      sendAccessLink: 'teacher/sendAccessLink',
      fetchAccessLink: 'teacher/fetchAccessLink',
      fetchTeacherList: 'teacher/fetchTeacherList',
    }),
    copyLink(e, ref) {
      const elem = this.$refs[ref];
      elem.select();
      console.log(elem)
      document.execCommand('copy');
      this.$toasted.success('скопировано в буфер обмена', {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    buildFormData() {
      // TODO refactor
      for (const key in this.getTeacherUserDetail) {
        const formKeys = Object.keys(this.form);
        if (formKeys.includes(key)) {
          this.form[key] = this.getTeacherUserDetail[key];
        }
      }
    },
    async sendAccessLinkByEmail() {
      this.isSendingEmail = true;
      try {
        const result = await this.sendAccessLink(this.$route.params.id);
        this.isSendingEmail = false;
        if (result.status === 'success') {
          this.$toasted.success('Письмо успешно отправлено.', {
            position: 'bottom-right',
            action: {
              text: 'Закрыть',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }
      this.isSendingEmail = false;
      this.$toasted.error(`Не удалось отправить!`, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    async fetchCategoryList() {
      try {
        const { data } = await this.$axios.get('crm/salary-categories');
        this.categoryList = data.data.map(c => {
          return { value: c.id, text: c.title };
        });
      } catch (ex) {
        console.log('cant fetch data: ', ex);
      }
    },
    async save() {
      try {
        console.log(this.formValidation());

        if (!this.formValidation()) {
          throw new Error('Не все необходимые поля заполнены')
        }

        const data = {...this.form};
        const result = await this.createTeacher(data);
        if (result.status === 'success') {
          await this.fetchTeacherList();
          // await this.$router.push({name: 'TeacherDetail', params: { id: result.id }});
          await this.$router.push({name: 'TeacherList'});
        }
        this.$toasted.success('Данные сохранены!', {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      } catch (e) {
        this.$toasted.error(`Не удалось сохранить! ${e.message}`, {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
    },
    formValidation() {
      if (this.form.locations.length === 0) {
        this.formValidate.isValidLocation = false;
        return false;
      } else {
        this.formValidate.isValidLocation = true;
      }

      return true;
    }
  },
  async created() {
    await this.fetchTeacherDetails(this.$route.params.id);
    await this.fetchAccessLink(this.$route.params.id);
    this.buildFormData();
    await this.fetchCategoryList();
  },

};
</script>

<style scoped lang='scss'>
.access-link-input-group {
  .right-split-btn {
    border-right: 2px solid #fff;
  }

  .caption {
    margin-left: 10px;
  }
}
</style>
