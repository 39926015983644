<template>
    <div>
        <h4 class='mb-4'>Список учителей</h4>
        <div class='widget'>
            <div class='filter__container'>
                <div class='row mb-3 justify-content-between'>
                    <div class='input-group col-4'>
                        <input type='search' class='form-control' @keyup='searchByEnter' v-model='searchWord' id='search-input'>
                        <span class='input-group-append'>
                            <button type='button' class='btn btn-default' @click='search'>Поиск</button>
                        </span>
                    </div>
                    <div class="col-4">
                        <VueMultiselect
                            class='custom__multiselect mr-3'
                            :multiple='true'
                            :close-on-select='false'
                            :clear-on-select='false'
                            :preserve-search='true'
                            selectedLabel='Выбран'
                            selectLabel='Добавить'
                            deselectLabel='Убрать'
                            placeholder='Выберите статус'
                            :tagable='true'
                            tagPosition='bottom'
                            label='value'
                            track-by='key'
                            :options='statuses'
                            v-model='selectedStatus'
                            @input='search'
                        >
                            <template #noOptions>Нет статусов</template>
                            <template #noResult>Ничего не найдено</template>
                        </VueMultiselect>
                    </div>
                    <div class='col-4 text-right'>
                        <button class='button_add' @click='addData'>Добавить преподавателя</button>
                    </div>
                </div>
                <div class='input-group col-4'>

                </div>
            </div>
            <table class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>фио</th>
                    <th>телефон</th>
                    <th>email</th>
                    <th>Статус</th>
                    <th class='header-profile'>профиль</th>
                    <th class='text-center action'>действия</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for='teacher in getTeacherList'
                    :key='teacher.id'
                >
                    <td>{{ teacher.full_name }}</td>
                    <td>{{ teacher.phone }}</td>
                    <td>{{ teacher.email }}</td>
                    <td>{{ teacher.status_title }}</td>
                    <td><span :class='getClass(teacher.profile_status)'>{{
                            teacher.profile_status ? teacher.profile_status : 'Нет профиля'
                        }}</span></td>
                    <td class='actions_container'>
                        <router-link class='leads_table_action_link btn mr-2 btn-primary'
                                     :to="{name: 'TeacherDetail', params: {id: teacher.id}}">
                            <span class='glyphicon glyphicon-edit'/>
                        </router-link>
                        <a class='btn mr-2 btn-danger' @click.prevent='deleteRow(teacher.id, teacher.full_name)'>
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteModal from '../../components/Modal/DeleteModal';
import Preloader from "@/components/Preloader";
import VueMultiselect from "vue-multiselect";

export default {
    name: 'TeacherList',
    components: {Preloader, VueMultiselect},
    data() {
        return {
            searchWord: null,
            statuses: [],
            selectedStatus: [{ "key": 0, "value": "Работает" } ],
        };
    },
    computed: {
        ...mapGetters({
            getTeacherList: 'teacher/getTeacherList',
        }),
    },
    watch: {
        searchWord(newVal, oldVal) {
            if (newVal === null || newVal === '') {
                this.fetchTeacherList();
            }
        },
    },
    methods: {
        getClass(str) {
            if (!str) {
                return 'badge-noprofile'
            }
            if (str === 'Опубликовано') return 'badge-published'
            if (str === 'Редактируется') return 'badge-edit'
            if (str === 'Нет профиля') return 'badge-noprofile'
        },
        ...mapActions({
            fetchTeacherList: 'teacher/fetchTeacherList',
            findTeachers: 'teacher/findTeachers',
            deleteRecord: 'teacher/deleteRecord',
        }),
        deleteRow(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                }, { name: 'DeleteModal' },
            );
        },
        addData(e) {
            e.preventDefault();
            this.$router.push({ name: 'TeacherAdd' });
        },
        async searchByEnter(e) {
            if (e.keyCode == 13) {
                await this.fetchTeachers();
            }
        },
        async search() {
            await this.fetchTeachers();
        },
        async fetchTeachers() {
            await this.findTeachers({searchWord: this.searchWord, statuses: this.selectedStatus});
        },
        async fetchStatuses() {
            try {
                this.loader = true;
                const {data} = await this.$axios.get('/crm/teachers/get-statuses');
                this.statuses = data.map((v, k) => {
                    return {key: k, value: v}
                });
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log('cant fetch statuses: ' + ex);
            }
        },
    },
    async created() {
        await this.fetchTeachers();
        await this.fetchStatuses();
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action {
    width: 100px;
}

.header-profile {
    width: 120px;
    text-align: center;
}

.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

@mixin badge($bg, $col) {
    background: $bg;
    color: $col;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.badge {
    &-noprofile {
        @include badge(#ee8c62, #fff);
    }
    &-published {
        @include badge(#70dba6, #000);
    }
    &-edit {
        @include badge(#faed5e, #000);
    }
}
</style>